import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BACKEND_URL } from '../constants';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`${BACKEND_URL}/auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                throw new Error('Login failed');
            }

            // Store jwt token for future use
            const token = await response.text();
            localStorage.setItem('jwt', token);
            console.log('JWT saved in local storage:', token);

            navigate('/dashboard'); // Move to Scene Selection

        } catch (error) {
            setError('Login failed. Please try again.');
            console.error('Error:', error);
        }
    };

    return (
        <div className="ui grid center aligned" style={{ height: '100vh' }}>
            <div className="four wide column middle aligned center aligned">
                <h2 className="ui teal image header">
                    <img src="/craneIconTealInverse.png" alt="Construction Yard Logo" className="ui rounded image"></img>
                    <div className="content">
                        <h1>
                            Construction Yard
                        </h1>
                    </div>
                </h2>
                <div className="ui raised segment">
                    <form onSubmit={handleSubmit} autoComplete="on" className="ui form">
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} name="username" placeholder="Group Name" autoComplete="on" />
                            </div>
                        </div>
                        <div className="field">
                            <div className="ui  left icon input">
                                <i className="lock icon"></i>
                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} name="password" placeholder="Password" autoComplete="on" />
                            </div>
                        </div>
                        <div className="ui divider"></div>
                        <button type="submit" className="ui fluid large teal button">Login</button>
                    </form>
                    {error && <p style={{ color: 'red', paddingTop: '10px' }}>{error}</p>}
                </div>
            </div>
        </div>
    );
}

export default Login;