import React, { useState, useEffect } from "react";

const SettingsModal = ({ isOpen, closeModal, skyBoxOn, handleSkyBoxToggle, handleTemplateDownload, handleSceneDownload, getBackgroundColour, handleBackgroundColourChange, getSkybox, handleSkyBoxChange }) => {
    const [transitionState, setTransitionState] = useState(""); 

    useEffect(() => {
      if ( isOpen) {
        setTransitionState("animating fade in"); 
      } else {
        setTransitionState("animating fade out"); 
      }
    }, [ isOpen]);

  return (
    <div>
      { isOpen && (
        <div>
          <div
            className={`ui dimmer modals visible active ${transitionState}`}
            onClick={closeModal} 
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1000, 
            }}
          ></div>

          <div
            className={`ui modal active ${transitionState}`}
            style={{
              zIndex: 1050,
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", 
              margin: "0", 
              width: "50vw"
            }}
          >
            <div className="header"  style={{ backgroundColor: '#00b5ad' }}>
              <h2 className="ui header" style={{ color: 'white', margin: 0 }}>Scene Settings</h2>
            </div>
            <div className="content">
                <h3 className="ui teal header">Background Colour</h3>
                <input type="color" value={getBackgroundColour()} onChange={(e) => {handleBackgroundColourChange(e)}}></input>
                <h3 className="ui teal header">Sky Box</h3>
                <div className="ui compact segment">
                  <div className="ui fitted toggle checkbox">
                      <input type="checkbox" checked={skyBoxOn} onChange={(e) => {handleSkyBoxToggle(e)}}/>
                      <label></label>
                  </div>
                </div>
                <select
                  className="ui selection dropdown"
                  style={{ padding: '5px', color: 'teal' }}
                  value={getSkybox()} 
                  onChange={(e) => {handleSkyBoxChange(e)}} 
                >
                  {["Space", "SpaceColour", "SpaceNoSun", "Mountain", "GrassField"].map((option) => (
                    <option key={option} value={option} style={{ color: 'teal' }}>
                      {option}
                    </option>
                  ))}
                </select>
            </div>
            <div className="actions">
                <button className="ui teal button" onClick={handleSceneDownload}>
                    Download Scene
                </button>
                <button className="ui teal button" onClick={handleTemplateDownload}>
                    Download WebGL Template
                </button>
                <button className="ui red button" onClick={closeModal}>
                    Close
                </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsModal;
