import React, { useState, useEffect } from "react";

const DeleteModal = ({ isOpen, closeModal, handleDeleteClick }) => {
    const [transitionState, setTransitionState] = useState(""); 

    useEffect(() => {
      if ( isOpen) {
        setTransitionState("animating fade in"); 
      } else {
        setTransitionState("animating fade out"); 
      }
    }, [ isOpen]);

  return (
    <div>
      { isOpen && (
        <div>
          <div
            className={`ui dimmer modals visible active ${transitionState}`}
            onClick={closeModal} 
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1000, 
            }}
          ></div>

          <div
            className={`ui modal active ${transitionState}`}
            style={{
              zIndex: 1050,
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", 
              margin: "0", 
              width: "30vw"
            }}
          >
            <div className="header"  style={{ backgroundColor: '#00b5ad' }}>
              <h2 className="ui header" style={{ color: 'white', margin: 0 }}>Delete Scene</h2>
            </div>
            <div className="content">
            <h3 className="ui teal header" style={{fontWeight: '400'}}><p>Are you sure you want to <span style={{color: 'red', fontWeight: 'bold'}}>DELETE</span> ?</p></h3>
            </div>
            <div className="actions">
              <div className="ui red button" onClick={() => {handleDeleteClick(); closeModal()}}>
                <i className="delete icon"></i>
                  Delete
              </div>
              <button className="ui red button" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteModal;
