const BACKEND_URL = "https://tehzwen.duckdns.org"
// "http://localhost:8080"
//"https://tehzwen.duckdns.org"
const DIFFUSE_TEXTURES = ["default.jpg", "scifiwallDiffuse.jpg", "metalGrillDiffuse.jpg", "metalGrill2Diffuse.jpg", "moon.jpg", "fruit.jpg", "plywood.jpg"]
const NORMAL_TEXTURES = ["defaultNorm.jpg", "scifiwallNormal.jpg", "metalGrillNormal.jpg", "metalGrill2Normal.jpg"]
const VERSION = "v1.05"

export {
    BACKEND_URL,
    DIFFUSE_TEXTURES,
    NORMAL_TEXTURES,
    VERSION
};