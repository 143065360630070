import { vec3, vec4, mat4 } from 'gl-matrix';
import Common from './CommonRender';
import shaderManagerInstance from './ShaderManager';


class Renderer {
    constructor(sceneFunctions) {
        this.gl = null;
        this.canvas = null;
        this.projectionMatrix = mat4.create();
        this.viewMatrix = mat4.create();
        this.getObject = sceneFunctions.getObject;
    }

    getViewMatrix() {
        return this.viewMatrix;
    }

    setViewMatrix(value) {
        this.viewMatrix = value;
    }

    drawRegular(sceneData) {
        if (!this.gl) {
            return;
        }
        let gl = this.gl;

        gl.enable(gl.DEPTH_TEST); // Enable depth testing
        gl.enable(gl.BLEND);
        gl.depthFunc(gl.LEQUAL); // Near things obscure far things
        gl.enable(gl.CULL_FACE);
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
        gl.enable(gl.STENCIL_TEST);
        gl.stencilFunc(gl.NOTEQUAL, 1, 0xFF);
        gl.stencilOp(gl.KEEP, gl.KEEP, gl.REPLACE);
        gl.clearDepth(1.0);
        gl.stencilMask(0x00);
        gl.clearColor(
            sceneData.sceneSettings.backgroundColor[0],
            sceneData.sceneSettings.backgroundColor[1],
            sceneData.sceneSettings.backgroundColor[2],
            1.0)
        gl.clear(gl.COLOR_BUFFER_BIT | gl.STENCIL_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
        //gl.viewport(0, 0, sceneData.dimensions.width, sceneData.dimensions.height);

        let sortedObjects = sceneData.objects.sort((a, b) => {
            let aCentroidFour = vec4.fromValues(a.centroid[0], a.centroid[1], a.centroid[2], 1.0);
            vec4.transformMat4(aCentroidFour, aCentroidFour, a.model.modelMatrix);

            let bCentroidFour = vec4.fromValues(b.centroid[0], b.centroid[1], b.centroid[2], 1.0);
            vec4.transformMat4(bCentroidFour, bCentroidFour, b.model.modelMatrix);

            return vec3.distance(sceneData.activeCam.position, vec3.fromValues(aCentroidFour[0], aCentroidFour[1], aCentroidFour[2]))
                >= vec3.distance(sceneData.activeCam.position, vec3.fromValues(bCentroidFour[0], bCentroidFour[1], bCentroidFour[2])) ? -1 : 1;
        });

        sortedObjects.forEach((object) => {
            if (object.loaded) {
                gl.useProgram(object.programInfo.program);
                {
                    //check if selected object
                    if (sceneData.selectedObject && object.name === sceneData.selectedObject.name) {
                        gl.stencilFunc(gl.ALWAYS, 1, 0xFF);
                        gl.stencilMask(0xFF);
                    }

                    if (object.material.alpha < 1.0) {
                        gl.disable(gl.CULL_FACE);
                        gl.disable(gl.DEPTH_TEST);
                    } else {
                        gl.enable(gl.CULL_FACE);
                        gl.enable(gl.DEPTH_TEST);
                    }

                    gl.uniformMatrix4fv(
                        object.programInfo.uniformLocations.uProjectionMatrix,
                        false,
                        sceneData.projectionMatrix);
                    this.applyView(object.programInfo, sceneData.activeCam);
                    this.applyTransformations(object, object.programInfo);
                    this.applyNormalMatrix(object, object.programInfo);
                    this.applyMaterial(object, object.programInfo);
                    this.applyPointLights(object.programInfo, sceneData.pointLights, sceneData.numPointLights);
                    this.applyTextures(object, object.programInfo);
                    if (object.material.shaderType == 6) {
                        this.applyCubeMapTextures(object.programInfo, sceneData.skyBox.model.skyBoxTexture);
                    }

                    {
                        // Bind the buffer we want to draw
                        gl.bindVertexArray(object.buffers.vao);
                        // Draw the object
                        const offset = 0; // Number of elements to skip before starting
                        //if its a mesh then we don't use an index buffer and use drawArrays instead of drawElements
                        if (object.type === "mesh" || object.type === "meshCustom") {
                            gl.drawArrays(gl.TRIANGLES, offset, object.buffers.numVertices / 3);
                        } else {
                            gl.drawElements(gl.TRIANGLES, object.buffers.numVertices, gl.UNSIGNED_SHORT, offset);
                        }
                        gl.bindTexture(gl.TEXTURE_2D, null);
                    }
                }
            }
        });
    }

    drawPicker(sceneData) {
        let gl = this.gl;
        this.bindPickerBuffers(sceneData.picker);
        const shader = shaderManagerInstance.getShader("picker");
        const shaderProgram = shader.getProgram();
        const programInfo = Common.initShaderUniforms(this.gl, shaderProgram, shader.uniforms, shader.attribs);

        sceneData.objects.forEach((object, index) => {
            gl.useProgram(programInfo.program);
            gl.uniformMatrix4fv(
                programInfo.uniformLocations.uProjectionMatrix,
                false,
                sceneData.projectionMatrix);
            this.applyView(programInfo, sceneData.activeCam);
            this.applyTransformations(object, programInfo);

            //create ID
            let uID = [
                ((((index + 1) >> 0) & 0xFF) / 0xFF).toFixed(5),
                ((((index + 1) >> 8) & 0xFF) / 0xFF).toFixed(5),
                ((((index + 1) >> 16) & 0xFF) / 0xFF).toFixed(5),
                1.0
            ]
            object.id = uID;
            gl.uniform4fv(programInfo.uniformLocations.uID, vec4.fromValues(uID[0], uID[1], uID[2], uID[3]));
            gl.bindVertexArray(object.buffers.vao);

            const offset = 0; // Number of elements to skip before starting
            //if its a mesh then we don't use an index buffer and use drawArrays instead of drawElements
            if (object.type === "mesh" || object.type === "meshCustom") {
                gl.drawArrays(gl.TRIANGLES, offset, object.buffers.numVertices / 3);
            } else {
                gl.drawElements(gl.TRIANGLES, object.buffers.numVertices, gl.UNSIGNED_SHORT, offset);
            }
        })
    }

    drawStencil(sceneData) {
        let gl = this.gl;
        if (!sceneData.selectedObject || sceneData.selectedObject.type === "pointLight") {
            return;
        }

        let object = sceneData.selectedObject;
        //gl.disable(gl.DEPTH_TEST); --- This line of code caused issues with the skybox and stencils.
        gl.stencilFunc(gl.NOTEQUAL, 1, 0xFF);
        gl.stencilMask(0x00);
        let scale = 1.05;

        const shader = shaderManagerInstance.getShader("stencil");
        const shaderProgram = shader.getProgram();
        const programInfo = Common.initShaderUniforms(this.gl, shaderProgram, shader.uniforms, shader.attribs);

        gl.useProgram(programInfo.program);
        gl.uniformMatrix4fv(
            programInfo.uniformLocations.uProjectionMatrix,
            false,
            sceneData.projectionMatrix);

        let activeCam = sceneData.activeCam;
        let viewMatrix = mat4.create();
        let camFront = vec3.fromValues(0, 0, 0);
        vec3.add(camFront, activeCam.position, activeCam.front);
        mat4.lookAt(
            viewMatrix,
            activeCam.position,
            camFront,
            activeCam.up,
        );
        gl.uniformMatrix4fv(programInfo.uniformLocations.uViewMatrix, false, viewMatrix);


        //custom logic for scaled outline
        let modelMatrix = mat4.create();
        let negCentroid = vec3.fromValues(0.0, 0.0, 0.0);
        vec3.negate(negCentroid, object.centroid);
        mat4.translate(modelMatrix, modelMatrix, object.model.position);
        mat4.translate(modelMatrix, modelMatrix, object.centroid);
        mat4.mul(modelMatrix, modelMatrix, object.model.rotation);
        mat4.scale(modelMatrix, modelMatrix, object.model.scale);
        mat4.scale(modelMatrix, modelMatrix, vec3.fromValues(scale, scale, scale));
        mat4.translate(modelMatrix, modelMatrix, negCentroid);

        if (object.parent) {
            let parent = this.getObject(object.parent);
            if (parent.model && parent.model.modelMatrix) {
                mat4.multiply(modelMatrix, parent.model.modelMatrix, modelMatrix);
            }
        }
        gl.uniformMatrix4fv(programInfo.uniformLocations.uModelMatrix, false, modelMatrix);
        gl.uniform4fv(programInfo.uniformLocations.uStencilColor, vec4.fromValues(0.96, 0.62, 0.09, 1.0));
        gl.bindVertexArray(object.buffers.vao);

        const offset = 0; // Number of elements to skip before starting
        //if its a mesh then we don't use an index buffer and use drawArrays instead of drawElements
        if (object.type === "mesh" || object.type === "meshCustom") {
            gl.drawArrays(gl.TRIANGLES, offset, object.buffers.numVertices / 3);
        } else {
            gl.drawElements(gl.TRIANGLES, object.buffers.numVertices, gl.UNSIGNED_SHORT, offset);
        }
        gl.stencilMask(0xFF);
        gl.stencilFunc(gl.ALWAYS, 0, 0xFF);

    }

    drawSkybox(sceneData) {
        if (!this.gl) {
            return;
        }
        let gl = this.gl;

        let object = sceneData.skyBox;
        // Use the shader program
        if (object.loaded) {
            gl.useProgram(object.programInfo.program);
            {
                // SKYBOX STUFF
                gl.uniformMatrix4fv(
                    object.programInfo.uniformLocations.uProjectionMatrix,
                    false,
                    sceneData.projectionMatrix);
                this.applyView(object.programInfo, sceneData.activeCam);
                // Disable depth mask
                gl.depthMask(false);
                // Create Cube Map Texture and Bind it
                this.applyCubeMapTextures(object.programInfo, object.model.skyBoxTexture);
                // Bind the VAO
                gl.bindVertexArray(object.buffers.vao);
                // Draw the cube
                const offset = 0;
                gl.drawElements(gl.TRIANGLES, object.buffers.numVertices, gl.UNSIGNED_SHORT, offset);
                // Re-enable depth mask
                gl.depthMask(true);
                this.gl.bindTexture(this.gl.TEXTURE_CUBE_MAP, null);
            }
        }
    }

    applyTransformations(object, programInfo) {
        //Apply transformations to model matrix
        let modelMatrix = mat4.create();
        let negCentroid = vec3.fromValues(0.0, 0.0, 0.0);
        vec3.negate(negCentroid, object.centroid);
        mat4.translate(modelMatrix, modelMatrix, object.model.position);
        mat4.translate(modelMatrix, modelMatrix, object.centroid);
        mat4.mul(modelMatrix, modelMatrix, object.model.rotation);
        mat4.scale(modelMatrix, modelMatrix, object.model.scale);
        mat4.translate(modelMatrix, modelMatrix, negCentroid);

        if (object.parent) {
            let parent = this.getObject(object.parent);
            if (parent && parent.model && parent.model.modelMatrix) {
                mat4.multiply(modelMatrix, parent.model.modelMatrix, modelMatrix);
            }
        }

        object.model.modelMatrix = modelMatrix;
        this.gl.uniformMatrix4fv(programInfo.uniformLocations.uModelMatrix, false, modelMatrix);
        return modelMatrix;
    }

    applyNormalMatrix(object, programInfo) {
        var normalMatrix = mat4.create();
        mat4.invert(normalMatrix, object.model.modelMatrix);
        mat4.transpose(normalMatrix, normalMatrix);
        this.gl.uniformMatrix4fv(programInfo.uniformLocations.normalMatrix, false, normalMatrix);
    }

    applyMaterial(object, programInfo) {
        this.gl.uniform3fv(programInfo.uniformLocations.diffuseVal, object.material.diffuse);
        this.gl.uniform3fv(programInfo.uniformLocations.ambientVal, object.material.ambient);
        this.gl.uniform3fv(programInfo.uniformLocations.specularVal, object.material.specular);
        this.gl.uniform1f(programInfo.uniformLocations.alpha, object.material.alpha);
        this.gl.uniform1f(programInfo.uniformLocations.nVal, object.material.n);
    }

    applyView(programInfo, activeCam) {
        let viewMatrix = mat4.create();
        //create camera front value
        let camFront = vec3.fromValues(0, 0, 0);
        vec3.add(camFront, activeCam.position, activeCam.front);
        mat4.lookAt(
            viewMatrix,
            activeCam.position,
            camFront,
            activeCam.up,
        );

        this.gl.uniformMatrix4fv(programInfo.uniformLocations.uViewMatrix, false, viewMatrix);
        this.gl.uniform3fv(programInfo.uniformLocations.uCameraPosition, activeCam.position);
        this.viewMatrix = viewMatrix;
        activeCam.setViewMatrix(viewMatrix);
    }

    applyPointLights(programInfo, pointLights, numPointLights) {
        this.gl.uniform1i(programInfo.uniformLocations.numPointLights, numPointLights);
        pointLights.forEach((pL, index) => {
            let tempLightPosition = vec4.fromValues(pL.position[0], pL.position[1], pL.position[2], 1.0);

            if (pL.parent) {
                let parent = this.getObject(pL.parent);
                if (parent.model && parent.model.modelMatrix) {
                    vec4.transformMat4(tempLightPosition, tempLightPosition, parent.model.modelMatrix);
                }
            }

            tempLightPosition = vec3.fromValues(tempLightPosition[0], tempLightPosition[1], tempLightPosition[2]);
            this.gl.uniform3fv(this.gl.getUniformLocation(programInfo.program, "pointLights[" + index + "].position"), tempLightPosition);
            this.gl.uniform3fv(this.gl.getUniformLocation(programInfo.program, "pointLights[" + index + "].color"), pL.colour);
            this.gl.uniform1f(this.gl.getUniformLocation(programInfo.program, "pointLights[" + index + "].strength"), pL.strength);
            this.gl.uniform1f(this.gl.getUniformLocation(programInfo.program, "pointLights[" + index + "].constant"), pL.constant);
            this.gl.uniform1f(this.gl.getUniformLocation(programInfo.program, "pointLights[" + index + "].linear"), pL.linear);
            this.gl.uniform1f(this.gl.getUniformLocation(programInfo.program, "pointLights[" + index + "].quadratic"), pL.quadratic);
        });
    }

    applyTextures(object, programInfo) {
        // check for diffuse texture and apply it
        if (object.model.texture != null && object.material.shaderType > 1) {
            this.gl.activeTexture(this.gl.TEXTURE0);
            this.gl.uniform1i(programInfo.uniformLocations.uTexture, 0);
            this.gl.bindTexture(this.gl.TEXTURE_2D, object.model.texture);
        }

        //check for normal texture and apply it
        if (object.model.textureNorm != null && object.material.shaderType > 3) {
            this.gl.activeTexture(this.gl.TEXTURE0 + 1);
            this.gl.uniform1i(programInfo.uniformLocations.uTextureNorm, 1);
            this.gl.bindTexture(this.gl.TEXTURE_2D, object.model.textureNorm);
        }
    }

    applyCubeMapTextures(programInfo, tex) {
        this.gl.activeTexture(this.gl.TEXTURE0);
        this.gl.bindTexture(this.gl.TEXTURE_CUBE_MAP, tex);
        this.gl.uniform1i(programInfo.uniformLocations.cubeTexture, 0);
    }

    bindPickerBuffers(picker) {
        let gl = this.gl;
        gl.clearColor(0.0, 0.0, 0.0, 1.0);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
        gl.enable(gl.DEPTH_TEST); // Enable depth testing

        gl.bindTexture(gl.TEXTURE_2D, picker.targetTexture);
        // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.bindRenderbuffer(gl.RENDERBUFFER, picker.depthBuffer);
        gl.bindFramebuffer(gl.FRAMEBUFFER, picker.frameBuffer);
        const attachmentPoint = gl.COLOR_ATTACHMENT0;
        const level = 0;
        gl.framebufferTexture2D(gl.FRAMEBUFFER, attachmentPoint, gl.TEXTURE_2D, picker.targetTexture, level);
        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, picker.depthBuffer);

        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA32F,
            this.canvas.width, this.canvas.height, 0,
            gl.RGBA, gl.FLOAT, null);

        gl.bindRenderbuffer(gl.RENDERBUFFER, picker.depthBuffer);
        gl.renderbufferStorage(gl.RENDERBUFFER, gl.DEPTH_COMPONENT32F, this.canvas.width, this.canvas.height);
    }
}

export default Renderer;
