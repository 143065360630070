import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from './pages/LoginForm';
import ProtectedRoute from './components/ProtectedRoute';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import Workspace from './pages/Workspace';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={
          <div>
            <LoginForm />
            <Footer/>
          </div>
          } 
        />
        <Route
          path="/"
          element={
            <div>
              <Header/>
              <Workspace/>
              <Footer/>
            </div>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
              <Footer/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/workspace"
          element={
            <ProtectedRoute>
              <Header/>
              <Workspace/>
              <Footer/>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;