import React, { useState, useEffect } from "react";

const AddObjectModal = ({ isOpen, closeModal, addObject, meshOptions }) => {
  const [transitionState, setTransitionState] = useState(""); 
  const [objectName, setObjectName] = useState(""); 
  const [objectType, setObjectType] = useState("Cube"); 
  const [customMesh, setCustomMesh] = useState(null); 

  useEffect(() => {
    if (isOpen) {
      setTransitionState("animating fade in"); 
    } else {
      setTransitionState("animating fade out"); 
    }
  }, [isOpen]);

  const handleAddObject = () => {
    if (objectName.trim() === "") {
      alert("Please enter a valid object name!");
      return;
    }
    addObject({
      name: objectName,
      type: objectType,
      customMesh: customMesh ? customMesh : "Lowpoly_tree_sample.obj",
    });
    setObjectName(""); 
    closeModal(); 
  };

  return (
    <div>
      {isOpen && (
        <div>
          <div
            className={`ui dimmer modals visible active ${transitionState}`}
            onClick={closeModal} 
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1000, 
            }}
          ></div>
          <div
            className={`ui modal active ${transitionState}`}
            style={{
              zIndex: 1050,
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", 
              margin: "0", 
              width: "30vw"
            }}
          >
            <div className="header" style={{ backgroundColor: '#00b5ad' }}>
              <h2 className="ui header" style={{ color: 'white', margin: 0 }}>Add Object</h2>
            </div>
            <div className="content">
              <h3 className="ui teal header" style={{ fontWeight: '400' }}>
                <p>Enter an object name and select an object type</p>
              </h3>
              <div className="ui teal input" style={{ marginRight: '10px' }}>
                <input
                  style={{ color: 'teal' }}
                  value={objectName}
                  onChange={(e) => {setObjectName(e.target.value);}}
                  placeholder="tempCube ..."
                />
              </div>
              <select
  className="ui selection dropdown"
  style={{ padding: "5px", color: "teal" }}
  value={objectType}
  onChange={(e) => {
    setObjectType(e.target.value);
    const selectedLabel = e.target.options[e.target.selectedIndex].dataset.label;
    setCustomMesh(selectedLabel); 

  }}
>
  {[
    { label: "Cube", value: "cube" },
    { label: "Light", value: "light" },
    { label: "Sphere", value: "sphere" },
    { label: "Plane", value: "plane" },
    { label: "Bunny", value: "bunny" },
    ...meshOptions.map((mesh) => ({ label: mesh, value: "mesh" })), 
  ].map((option) => (
    <option
      key={option.value}
      value={option.value}
      data-label={option.label}
      style={{ color: "teal" }}
    >
      {option.label}
    </option>
  ))}
</select>


            </div>
            <div className="actions">
              <button className="ui green button" onClick={handleAddObject}>
                <i className="add icon"></i>
                Add
              </button>
              <button className="ui red button" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddObjectModal;
