import React, { useState, useEffect } from "react";

const CreateModal = ({ isOpen, closeModal, handleCreateClick, handleSceneNameChange }) => {
    const [transitionState, setTransitionState] = useState(""); 

    useEffect(() => {
      if ( isOpen) {
        setTransitionState("animating fade in"); 
      } else {
        setTransitionState("animating fade out"); 
      }
    }, [ isOpen]);

  return (
    <div>
      { isOpen && (
        <div>
          <div
            className={`ui dimmer modals visible active ${transitionState}`}
            onClick={closeModal} 
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1000, 
            }}
          ></div>

          <div
            className={`ui modal active ${transitionState}`}
            style={{
              zIndex: 1050,
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", 
              margin: "0", 
              width: "30vw"
            }}
          >
            <div className="header"  style={{ backgroundColor: '#00b5ad' }}>
              <h2 className="ui header" style={{ color: 'white', margin: 0 }}>Create Scene</h2>
            </div>
            <div className="content">
              <h3 className="ui teal header"><p>Enter a name for your scene</p></h3>
              <div className="ui input">
                <input placeholder="Untitled" onChange={(e) => handleSceneNameChange(e.target.value.replace(/\s/g, '_'))}></input>
              </div>
            </div>
            <div className="actions">
              <div className="ui green button" onClick={() => {handleCreateClick(); closeModal()}}>
                <i className="plus icon"></i>
                  Create
              </div>
              <button className="ui red button" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateModal;
