const constants = require('../constants.js');

function Footer() {
    return (
        <div>
            <p style={{padding: '10px'}}>Version: {constants.VERSION}</p>
        </div>
    );
}

export default Footer;