import { vec3, mat4 } from 'gl-matrix';
import Common from '../CommonRender';
import RenderObject from './RenderObject';
import shaderManagerInstance, { SHADER_TYPES } from '../ShaderManager';


class Sphere extends RenderObject {
  constructor(glContext, object) {
    super(object);
    this.state = {};
    this.gl = glContext;
    this.name = object.name;
    this.parent = object.parent;
    this.type = object.type;
    this.loaded = false;
    this.initialTransform = { position: object.position, scale: object.scale, rotation: object.rotation };
    this.reflective = object.reflective;
    this.refractionIndex = object.refractionIndex;
    this.material = object.material;
    this.collide = object.collide;
    this.model = {
      tangents: [],
      triangles: [],
      bitangents: [],
      diffuseTexture: object.diffuseTexture ? object.diffuseTexture : "default.jpg",
      normalTexture: object.normalTexture ? object.normalTexture : "defaultNorm.jpg",
      tid: object.tid ? object.tid : null,
      buffers: null,
      modelMatrix: mat4.create(),
      position: vec3.fromValues(0.0, 0.0, 0.0),
      rotation: mat4.create(),
      scale: vec3.fromValues(1.0, 1.0, 1.0),
    };
    this.smooth = object.smooth != null ? object.smooth : false;
    this.horizontalSegments = object.horizontalSegments && object.horizontalSegments > 36 ? object.horizontalSegments : 36;
    this.verticalSegments = object.verticalSegments && object.verticalSegments > 18 ? object.verticalSegments : 18;
    this.radius = object.radius && object.radius > 1 ? object.radius : 1;

    this.testSphere = new InternalSphere(
      this.radius,
      this.horizontalSegments,
      this.verticalSegments,
      this.smooth
    );
    this.model.vertices = this.testSphere.vertices;
    this.model.normals = this.testSphere.normals;
    this.model.triangles = this.testSphere.indices;
    this.model.uvs = this.testSphere.texCoords;
  }

  rotate(axis, angle) {
    if (axis === 'x') {
      mat4.rotateX(this.model.rotation, this.model.rotation, angle)
    } else if (axis == 'y') {
      mat4.rotateY(this.model.rotation, this.model.rotation, angle)
    } else if (axis == 'z') {
      mat4.rotateZ(this.model.rotation, this.model.rotation, angle)
    }
  }

  exportValues() {
    return super.exportValues({
      radius: this.radius,
      verticalSegments: this.verticalSegments,
      horizontalSegments: this.horizontalSegments,
      smooth: this.smooth
    });
  }

  scale(scaleVec) {
    //model scale
    let xVal = this.model.scale[0];
    let yVal = this.model.scale[1];
    let zVal = this.model.scale[2];

    xVal *= scaleVec[0];
    yVal *= scaleVec[1];
    zVal *= scaleVec[2];

    //need to scale bounding box
    this.model.scale = vec3.fromValues(xVal, yVal, zVal);
  }

  translate(translateVec) {
    vec3.add(this.model.position, this.model.position, translateVec);
  }

  lightingShader() {
    return new Promise((resolve, reject) => {
      var shaderProgram;
      var programInfo;

      if (this.material.shaderType === SHADER_TYPES.BASIC) {
        // load in the basic shader here
        const shader = shaderManagerInstance.getShader("basicShader");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(this.gl, shaderProgram, shader.uniforms, shader.attribs);
        this.programInfo = programInfo;
        resolve();
      } else if (this.material.shaderType === SHADER_TYPES.BLINN_NO_TEXTURE) {
        const shader = shaderManagerInstance.getShader("blinnNoTexture");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(this.gl, shaderProgram, shader.uniforms, shader.attribs);
        this.programInfo = programInfo;
        resolve();
      } else if (this.material.shaderType === SHADER_TYPES.BASIC_DEPTH) {
        const shader = shaderManagerInstance.getShader("basicDepth");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(this.gl, shaderProgram, shader.uniforms, shader.attribs);
        this.programInfo = programInfo;
        resolve();
      } else if (this.material.shaderType === SHADER_TYPES.BLINN_TEXTURE) {
        const shader = shaderManagerInstance.getShader("blinnTexture");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(this.gl, shaderProgram, shader.uniforms, shader.attribs);
        this.programInfo = programInfo;
        resolve();
      } else if (this.material.shaderType === SHADER_TYPES.BLINN_NORMAL_TEXTURE) {
        const shader = shaderManagerInstance.getShader("blinnDiffuseAndNormal");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(this.gl, shaderProgram, shader.uniforms, shader.attribs);
        this.programInfo = programInfo;
        resolve();
      } else if (this.material.shaderType === SHADER_TYPES.ENVIRONMENT_MAP) {
        const shader = shaderManagerInstance.getShader("environmentMap");
        shaderProgram = shader.getProgram();
        programInfo = Common.initShaderUniforms(this.gl, shaderProgram, shader.uniforms, shader.attribs);
        this.programInfo = programInfo;
        resolve();
      } else {
        reject("No shader found for this material type");
      }
    })
  }

  initBuffers() {
    return new Promise((resolve) => {
      const tangents = new Float32Array(this.model.tangents.flat());
      const bitangents = new Float32Array(this.model.bitangents.flat());

      var vertexArrayObject = this.gl.createVertexArray();
      this.gl.bindVertexArray(vertexArrayObject);
      this.buffers = {
        vao: vertexArrayObject,
        attributes: {
          position: this.programInfo.attribLocations.vertexPosition != null ? this.initPositionAttribute(this.gl, this.programInfo, this.model.vertices) : null,
          normal: this.programInfo.attribLocations.vertexNormal != null ? this.initNormalAttribute(this.gl, this.programInfo, this.model.normals) : null,
          uv: this.programInfo.attribLocations.vertexUV != null ? this.initTextureCoords(this.gl, this.programInfo, this.model.uvs) : null,
          tangents: this.programInfo.attribLocations.vertexTangent != null ? this.initTangentBuffer(this.gl, this.programInfo, tangents) : null,
          bitangents: this.programInfo.attribLocations.vertexBitangent != null ? this.initBitangentBuffer(this.gl, this.programInfo, bitangents) : null
        },
        indicies: this.initIndexBuffer(this.gl, this.model.triangles),
        numVertices: this.model.triangles.length
      }
      resolve();
    })
  }

  reset() {
    return new Promise((resolve, reject) => {
      this.setup(true)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  setup(reset) {
    return new Promise((resolve, reject) => {
      const tangentData = this.calculateBitangents();
      this.model.bitangents = tangentData.bitangents;
      this.model.tangents = tangentData.tangents;
      this.getTextures(this.gl, this.model.diffuseTexture, this.model.tid)
        .then((data) => {
          this.model.texture = data;
          this.getTextures(this.gl, this.model.normalTexture, this.model.tid)
            .then((data) => {
              this.model.textureNorm = data;
              this.calculateCentroid(this.model.vertices);
              this.lightingShader().then(() => {
                this.initBuffers()
                  .then(() => {
                    if (!reset) {
                      this.scale(this.initialTransform.scale);
                      this.translate(this.initialTransform.position);

                      if (this.initialTransform.rotation) {
                        this.model.rotation = this.initialTransform.rotation;
                      }
                    }
                    this.loaded = true;
                    resolve();
                  })
              })
            })
            .catch((err) => {
              reject(err);
            })
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  delete() {
    Object.keys(this.buffers.attributes).forEach((key) => {
      this.gl.deleteBuffer(this.buffers.attributes[key]);
    })
    this.gl.deleteBuffer(this.buffers.indicies);
  }
}

// Internal Sphere constructor
let InternalSphere = function (radius = 1, sectors = 36, stacks = 18, smooth = true) {
  this.radius = 1;
  this.sectorCount = 36;
  this.stackCount = 18;
  this.smooth = true;
  this.vertices = [];
  this.normals = [];
  this.texCoords = [];
  this.indices = [];
  this.interleavedVertices = [];
  this.stride = 32;   // stride for interleaved vertices, always=32
  // init
  this.set(radius, sectors, stacks, smooth);
};

InternalSphere.prototype =
{
  set: function (r, se, st, sm) {
    this.radius = r;
    this.sectorCount = se;
    if (se < 3)
      this.sectorCount = 3;
    this.stackCount = st;
    if (st < 2)
      this.stackCount = 2;
    if (se > 100)
      this.sectorCount = 100
    if (st > 100)
      this.stackCount = 100

    this.smooth = sm;
    if (sm)
      this.buildVerticesSmooth();
    else
      this.buildVerticesFlat();
    return this;
  },
  setRadius: function (r) {
    if (this.radius != r)
      this.set(r, this.sectorCount, this.stackCount, this.smooth);
    return this;
  },
  setSectorCount: function (s) {
    if (this.sectorCount != s)
      this.set(this.radius, s, this.stackCount, this.smooth);
    return this;
  },
  setStackCount: function (s) {
    if (this.stackCount != s)
      this.set(this.radius, this.sectorCount, s, this.smooth);
    return this;
  },
  setSmooth: function (s) {
    if (this.smooth != s) {
      this.smooth = s;
      if (this.smooth)
        this.buildVerticesSmooth();
      else
        this.buildVerticesFlat();
    }
    return this;
  },
  reverseNormals: function () {
    let i, j;
    let count = this.normals.length;
    for (i = 0, j = 3; i < count; i += 3, j += 8) {
      this.normals[i] *= -1;
      this.normals[i + 1] *= -1;
      this.normals[i + 2] *= -1;

      this.interleavedVertices[j] = this.normals[i];
      this.interleavedVertices[j + 1] = this.normals[i + 1];
      this.interleavedVertices[j + 2] = this.normals[i + 2];
    }

    let tmp;
    count = this.indices.length;
    for (i = 0; i < count; i += 3) {
      tmp = this.indices[i];
      this.indices[i] = this.indices[i + 2];
      this.indices[i + 2] = tmp;
    }
  },
  getTriangleCount: function () {
    return this.getIndexCount() / 3;
  },
  getIndexCount: function () {
    return this.indices.length;
  },
  getVertexCount: function () {
    return this.vertices.length / 3;
  },
  getNormalCount: function () {
    return this.normals.length / 3;
  },
  getTexCoordCount: function () {
    return this.texCoords.length / 2;
  },

  clearArrays: function () {
    this.vertices.length = 0;
    this.normals.length = 0;
    this.texCoords.length = 0;
    this.indices.length = 0;
    this.interleavedVertices.length = 0;
  },
  resizeArraysSmooth: function () {
    this.clearArrays();
    let count = (this.sectorCount + 1) * (this.stackCount + 1);
    this.vertices = new Float32Array(3 * count);
    this.normals = new Float32Array(3 * count);
    this.texCoords = new Float32Array(2 * count);
    //this.indices = new Uint16Array(6 * this.sectorCount + 6 * (this.stackCount - 2) * this.sectorCount);
    this.indices = new Uint16Array(6 * this.sectorCount * (this.stackCount - 1));
  },
  resizeArraysFlat: function () {
    this.clearArrays();
    let count = 6 * this.sectorCount + 4 * this.sectorCount * (this.stackCount - 2);
    this.vertices = new Float32Array(3 * count);
    this.normals = new Float32Array(3 * count);
    this.texCoords = new Float32Array(2 * count);
    //this.indices = new Uint16Array(6 * this.sectorCount + 6 * (this.stackCount - 2) * this.sectorCount);
    this.indices = new Uint16Array(6 * this.sectorCount * (this.stackCount - 1));
  },

  ///////////////////////////////////////////////////////////////////////////
  // generate vertices of sphere with smooth shading
  // x = r * cos(u) * cos(v)
  // y = r * cos(u) * sin(v)
  // z = r * sin(u)
  // where u: stack(latitude) angle (-90 <= u <= 90)
  //       v: sector(longitude) angle (0 <= v <= 360)
  ///////////////////////////////////////////////////////////////////////////
  buildVerticesSmooth: function () {
    // resize typed arrays
    this.resizeArraysSmooth();

    let x, y, z, xy, nx, ny, nz, s, t, i, j, k1, k2, ii, jj, kk;
    let lengthInv = 1.0 / this.radius;
    let sectorStep = 2 * Math.PI / this.sectorCount;
    let stackStep = Math.PI / this.stackCount;
    let sectorAngle, stackAngle;

    ii = jj = kk = 0;
    for (i = 0; i <= this.stackCount; ++i) {
      stackAngle = Math.PI / 2 - i * stackStep;   // starting from pi/2 to -pi/2
      xy = this.radius * Math.cos(stackAngle);    // r * cos(u)
      z = this.radius * Math.sin(stackAngle);     // r * sin(u)

      // add (sectorCount+1) vertices per stack
      // the first and last vertices have same position and normal, but different tex coords
      for (j = 0; j <= this.sectorCount; ++j) {
        sectorAngle = j * sectorStep;           // starting from 0 to 2pi

        // vertex position
        x = xy * Math.cos(sectorAngle);         // r * cos(u) * cos(v)
        y = xy * Math.sin(sectorAngle);         // r * cos(u) * sin(v)
        this.addVertex(ii, x, y, z);

        // normalized vertex normal
        nx = x * lengthInv;
        ny = y * lengthInv;
        nz = z * lengthInv;
        this.addNormal(ii, nx, ny, nz);

        // vertex tex coord between [0, 1]
        s = j / this.sectorCount;
        t = i / this.stackCount;
        this.addTexCoord(jj, s, t);

        // next
        ii += 3;
        jj += 2;
      }
    }

    // indices
    //  k1--k1+1
    //  |  / |
    //  | /  |
    //  k2--k2+1
    for (i = 0; i < this.stackCount; ++i) {
      k1 = i * (this.sectorCount + 1);            // beginning of current stack
      k2 = k1 + this.sectorCount + 1;             // beginning of next stack

      for (j = 0; j < this.sectorCount; ++j, ++k1, ++k2) {
        // 2 triangles per sector excluding 1st and last stacks
        if (i != 0) {
          this.addIndices(kk, k1, k2, k1 + 1);  // k1---k2---k1+1
          kk += 3;
        }

        if (i != (this.stackCount - 1)) {
          this.addIndices(kk, k1 + 1, k2, k2 + 1);// k1+1---k2---k2+1
          kk += 3;
        }
      }
    }

    // generate interleaved vertex array as well
    this.buildInterleavedVertices();
  },

  ///////////////////////////////////////////////////////////////////////////
  // generate vertices of sphere with flat shading
  ///////////////////////////////////////////////////////////////////////////
  buildVerticesFlat: function () {
    let i, j, z, n, xy, v1, v2, v3, v4, vi1, vi2, index, ii, jj, kk;
    let sectorStep = 2 * Math.PI / this.sectorCount;
    let stackStep = Math.PI / this.stackCount;
    let sectorAngle, stackAngle;
    let tmpVertices = [];
    let vertex = {};    // to store (x,y,z,s,t)

    // compute all vertices first, each vertex contains (x,y,z,s,t) except normal
    for (i = 0; i <= this.stackCount; ++i) {
      stackAngle = Math.PI / 2 - i * stackStep;       // starting from pi/2 to -pi/2
      xy = this.radius * Math.cos(stackAngle);        // r * cos(u)
      z = this.radius * Math.sin(stackAngle);         // r * sin(u)

      // add (sectorCount+1) vertices per stack
      // the first and last vertices have same position and normal, but different tex coords
      for (j = 0; j <= this.sectorCount; ++j) {
        sectorAngle = j * sectorStep;               // starting from 0 to 2pi
        vertex = {
          x: xy * Math.cos(sectorAngle),    // x = r * cos(u) * cos(v)
          y: xy * Math.sin(sectorAngle),    // y = r * cos(u) * sin(v)
          z: z,                             // z = r * sin(u)
          s: j / this.sectorCount,
          t: i / this.stackCount
        };
        tmpVertices.push(vertex);
      }
    }

    // resize typed arrays
    this.resizeArraysFlat();

    ii = jj = kk = index = 0;
    for (i = 0; i < this.stackCount; ++i) {
      vi1 = i * (this.sectorCount + 1);               // index of tmpVertices
      vi2 = (i + 1) * (this.sectorCount + 1);

      for (j = 0; j < this.sectorCount; ++j, ++vi1, ++vi2) {
        // get 4 vertices per sector
        //  v1-v3
        //  |  |
        //  v2-v4
        v1 = tmpVertices[vi1];
        v2 = tmpVertices[vi2];
        v3 = tmpVertices[vi1 + 1];
        v4 = tmpVertices[vi2 + 1];

        // if 1st stack and last stack, store only 1 triangle per sector
        // otherwise, store 2 triangles (quad) per sector
        if (i == 0) // a triangle for first stack ======================
        {
          // put a triangle
          this.addVertex(ii, v1.x, v1.y, v1.z);
          this.addVertex(ii + 3, v2.x, v2.y, v2.z);
          this.addVertex(ii + 6, v4.x, v4.y, v4.z);

          // put tex coords of triangle
          this.addTexCoord(jj, v1.s, v1.t);
          this.addTexCoord(jj + 2, v2.s, v2.t);
          this.addTexCoord(jj + 4, v4.s, v4.t);

          // put normal
          n = InternalSphere.computeFaceNormal(v1.x, v1.y, v1.z, v2.x, v2.y, v2.z, v4.x, v4.y, v4.z);
          this.addNormal(ii, n[0], n[1], n[2]);
          this.addNormal(ii + 3, n[0], n[1], n[2]);
          this.addNormal(ii + 6, n[0], n[1], n[2]);

          // put indices of 1 triangle
          this.addIndices(kk, index, index + 1, index + 2);

          // next
          ii += 9;
          jj += 6;
          kk += 3;
          index += 3;
        }
        else if (i == (this.stackCount - 1)) // a triangle for last stack =====
        {
          // put a triangle
          this.addVertex(ii, v1.x, v1.y, v1.z);
          this.addVertex(ii + 3, v2.x, v2.y, v2.z);
          this.addVertex(ii + 6, v3.x, v3.y, v3.z);

          // put tex coords of triangle
          this.addTexCoord(jj, v1.s, v1.t);
          this.addTexCoord(jj + 2, v2.s, v2.t);
          this.addTexCoord(jj + 4, v3.s, v3.t);

          // put normal
          n = InternalSphere.computeFaceNormal(v1.x, v1.y, v1.z, v2.x, v2.y, v2.z, v3.x, v3.y, v3.z);
          this.addNormal(ii, n[0], n[1], n[2]);
          this.addNormal(ii + 3, n[0], n[1], n[2]);
          this.addNormal(ii + 6, n[0], n[1], n[2]);

          // put indices of 1 triangle
          this.addIndices(kk, index, index + 1, index + 2);

          // next
          ii += 9;
          jj += 6;
          kk += 3;
          index += 3;
        }
        else // 2 triangles for others ================================
        {
          // put quad vertices: v1-v2-v3-v4
          this.addVertex(ii, v1.x, v1.y, v1.z);
          this.addVertex(ii + 3, v2.x, v2.y, v2.z);
          this.addVertex(ii + 6, v3.x, v3.y, v3.z);
          this.addVertex(ii + 9, v4.x, v4.y, v4.z);

          // put tex coords of quad
          this.addTexCoord(jj, v1.s, v1.t);
          this.addTexCoord(jj + 2, v2.s, v2.t);
          this.addTexCoord(jj + 4, v3.s, v3.t);
          this.addTexCoord(jj + 6, v4.s, v4.t);

          // put normal
          n = InternalSphere.computeFaceNormal(v1.x, v1.y, v1.z, v2.x, v2.y, v2.z, v3.x, v3.y, v3.z);
          this.addNormal(ii, n[0], n[1], n[2]);
          this.addNormal(ii + 3, n[0], n[1], n[2]);
          this.addNormal(ii + 6, n[0], n[1], n[2]);

          // put indices of quad (2 triangles)
          this.addIndices(kk, index, index + 1, index + 2);
          this.addIndices(kk + 3, index + 2, index + 1, index + 3);

          // next
          ii += 12;
          jj += 8;
          kk += 6;
          index += 4;
        }
      }
    }

    // generate interleaved vertex array as well
    this.buildInterleavedVertices();
  },

  ///////////////////////////////////////////////////////////////////////////
  // generate interleaved vertices: V/N/T
  // stride must be 32 bytes
  ///////////////////////////////////////////////////////////////////////////
  buildInterleavedVertices: function () {
    let vertexCount = this.getVertexCount();
    this.interleavedVertices.length = 0;
    this.interleavedVertices = new Float32Array(vertexCount * 8); // v(3)+n(3)+t(2)

    let i, j, k;
    for (i = 0, j = 0, k = 0; i < this.vertices.length; i += 3, j += 2, k += 8) {
      this.interleavedVertices[k] = this.vertices[i];
      this.interleavedVertices[k + 1] = this.vertices[i + 1];
      this.interleavedVertices[k + 2] = this.vertices[i + 2];

      this.interleavedVertices[k + 3] = this.normals[i];
      this.interleavedVertices[k + 4] = this.normals[i + 1];
      this.interleavedVertices[k + 5] = this.normals[i + 2];

      this.interleavedVertices[k + 6] = this.texCoords[j];
      this.interleavedVertices[k + 7] = this.texCoords[j + 1];
    }
  },

  ///////////////////////////////////////////////////////////////////////////
  // add vertex, normal, texcoord and indices
  ///////////////////////////////////////////////////////////////////////////
  addVertex: function (index, x, y, z) {
    this.vertices[index] = x;
    this.vertices[index + 1] = y;
    this.vertices[index + 2] = z;
  },
  addNormal: function (index, x, y, z) {
    this.normals[index] = x;
    this.normals[index + 1] = y;
    this.normals[index + 2] = z;
  },
  addTexCoord: function (index, s, t) {
    this.texCoords[index] = s;
    this.texCoords[index + 1] = t;
  },
  addIndices: function (index, i1, i2, i3) {
    this.indices[index] = i1;
    this.indices[index + 1] = i2;
    this.indices[index + 2] = i3;
  }
};



///////////////////////////////////////////////////////////////////////////////
// class (static) functions
///////////////////////////////////////////////////////////////////////////////
InternalSphere.computeFaceNormal = function (x1, y1, z1, x2, y2, z2, x3, y3, z3) {
  let normal = new Float32Array(3);
  let ex1 = x2 - x1;
  let ey1 = y2 - y1;
  let ez1 = z2 - z1;
  let ex2 = x3 - x1;
  let ey2 = y3 - y1;
  let ez2 = z3 - z1;
  // cross product: e1 x e2;
  let nx = ey1 * ez2 - ez1 * ey2;
  let ny = ez1 * ex2 - ex1 * ez2;
  let nz = ex1 * ey2 - ey1 * ex2;
  let length = Math.sqrt(nx * nx + ny * ny + nz * nz);
  if (length > 0.000001) {
    normal[0] = nx / length;
    normal[1] = ny / length;
    normal[2] = nz / length;
  }
  return normal;
}

export default Sphere;
