import Common from '../CommonRender';
import shaderManagerInstance from '../ShaderManager';


class SkyBox {
    constructor(glContext) {
        this.state = {};
        this.gl = glContext;
        this.name = "SkyBox";
        this.type = "skyBox";
        this.loaded = false;
        this.model = {
            vertices: [
                //front face
                -1.0, -1.0, -1.0,
                -1.0, 1.0, -1.0,
                1.0, 1.0, -1.0,
                1.0, -1.0, -1.0,

                //back face
                -1.0, -1.0, 1.0,
                -1.0, 1.0, 1.0,
                1.0, 1.0, 1.0,
                1.0, -1.0, 1.0,

                //top face
                -1.0, 1.0, 1.0,
                -1.0, 1.0, -1.0,
                1.0, 1.0, -1.0,
                1.0, 1.0, 1.0,

                //bottom face
                -1.0, -1.0, 1.0,
                1.0, -1.0, 1.0,
                1.0, -1.0, -1.0,
                -1.0, -1.0, -1.0,

                //right face
                1.0, -1.0, 1.0,
                1.0, -1.0, -1.0,
                1.0, 1.0, 1.0,
                1.0, 1.0, -1.0,

                //left face
                -1.0, -1.0, 1.0,
                -1.0, -1.0, -1.0,
                -1.0, 1.0, 1.0,
                -1.0, 1.0, -1.0,
            ],
            triangles: [
                //front face
                2, 1, 0, 3, 2, 0,

                //backface
                5, 6, 4, 6, 7, 4,

                //top face
                10, 8, 9, 10, 11, 8,

                //bottom face
                13, 14, 12, 14, 15, 12,

                //right face
                18, 17, 16, 18, 19, 17,

                //left face
                22, 20, 21, 23, 22, 21,
            ],
            skyBoxTexture: null,
            skyBoxImages: [],
            skyBoxImgPaths: ["posx.jpg", "negx.jpg", "posy.jpg", "negy.jpg", "posz.jpg", "negz.jpg"],
            buffers: null
        };
    }

    getCubeMapTexture(skyBoxPath, imgPath) {
        return new Promise((resolve, reject) => {
            let path = "./materials/SkyBoxes/" + skyBoxPath + "/" + imgPath;
            if (path) {
                const image = new Image();
                image.src = path;

                image.onload = () => {
                    resolve(image);
                }

                image.onerror = (err) => {
                    console.log(image);
                    reject(err);
                }

            } else {
                reject("Cannot find texture " + path);
            }
        });
    }

    async getCubeMapTextures(skyBox) {
        this.model.skyBoxImages = [];
        for (let i = 0; i < 6; i++) {
            let img = await this.getCubeMapTexture(skyBox, this.model.skyBoxImgPaths[i]);
            this.model.skyBoxImages.push(img);
        }
    }

    loadCubeMap(imgArray) {
        return new Promise((resolve) => {
            if (imgArray.length != 6) {
                return null;
            }

            var tex = this.gl.createTexture();
            this.gl.bindTexture(this.gl.TEXTURE_CUBE_MAP, tex);

            for (var i = 0; i < 6; i++) {
                //this.gl.texImage2D(this.gl.TEXTURE_CUBE_MAP_POSITIVE_X + i, 0, this.gl.RGBA, 1, 1, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, new Uint8Array([Math.random() * 255, Math.random() * 255, Math.random() * 255, 255]));
                this.gl.texImage2D(this.gl.TEXTURE_CUBE_MAP_POSITIVE_X + i, 0, this.gl.RGB, imgArray[i].width, imgArray[i].height, 0, this.gl.RGB, this.gl.UNSIGNED_BYTE, imgArray[i]);
            }

            this.gl.texParameteri(this.gl.TEXTURE_CUBE_MAP, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
            this.gl.texParameteri(this.gl.TEXTURE_CUBE_MAP, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
            this.gl.texParameteri(this.gl.TEXTURE_CUBE_MAP, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
            this.gl.texParameteri(this.gl.TEXTURE_CUBE_MAP, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
            this.gl.texParameteri(this.gl.TEXTURE_CUBE_MAP, this.gl.TEXTURE_WRAP_R, this.gl.CLAMP_TO_EDGE);

            this.model.skyBoxTexture = tex;
            resolve();
        })
    }

    setShader() {
        return new Promise((resolve, reject) => {
            var shaderProgram;
            var programInfo;

            // load in the sky box shader
            const shader = shaderManagerInstance.getShader("skyBox");
            if (!shader) {
                reject("No shader found for this material type");
            }
            shaderProgram = shader.getProgram();
            programInfo = Common.initShaderUniforms(this.gl, shaderProgram, shader.uniforms, shader.attribs);
            this.programInfo = programInfo;
            resolve();
        })
    }

    initIndexBuffer(gl, elementArray) {
        // Create a buffer for the positions.
        const indexBuffer = gl.createBuffer();

        // Select the buffer as the one to apply buffer
        // operations to from here out.
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);

        // Now pass the list of positions into WebGL to build the
        // shape. We do this by creating a Float32Array from the
        // JavaScript array, then use it to fill the current buffer.
        gl.bufferData(
            gl.ELEMENT_ARRAY_BUFFER, // The kind of buffer this is
            elementArray, // The data in an Array object
            gl.STATIC_DRAW // We are not going to change this data, so it is static
        );

        return indexBuffer;
    }

    initPositionAttribute(gl, programInfo, positionArray) {

        // Create a buffer for the positions.
        const positionBuffer = gl.createBuffer();

        // Select the buffer as the one to apply buffer
        // operations to from here out.
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

        // Now pass the list of positions into WebGL to build the
        // shape. We do this by creating a Float32Array from the
        // JavaScript array, then use it to fill the current buffer.
        gl.bufferData(
            gl.ARRAY_BUFFER, // The kind of buffer this is
            positionArray, // The data in an Array object
            gl.STATIC_DRAW // We are not going to change this data, so it is static
        );

        // Tell WebGL how to pull out the positions from the position
        // buffer into the vertexPosition attribute.
        {
            const numComponents = 3; // pull out 3 values per iteration, ie vec3
            const type = gl.FLOAT; // the data in the buffer is 32bit floats
            const normalize = false; // don't normalize between 0 and 1
            const stride = 0; // how many bytes to get from one set of values to the next
            // Set stride to 0 to use type and numComponents above
            const offset = 0; // how many bytes inside the buffer to start from


            // Set the information WebGL needs to read the buffer properly
            gl.vertexAttribPointer(
                programInfo.attribLocations.vertexPosition,
                numComponents,
                type,
                normalize,
                stride,
                offset
            );
            // Tell WebGL to use this attribute
            gl.enableVertexAttribArray(
                programInfo.attribLocations.vertexPosition);
        }

        return positionBuffer;
    }

    initBuffers() {
        return new Promise((resolve) => {
            //create vertices and indicies arrays
            const positions = new Float32Array(this.model.vertices);
            const indices = new Uint16Array(this.model.triangles);

            var vertexArrayObject = this.gl.createVertexArray();
            this.gl.bindVertexArray(vertexArrayObject);

            this.buffers = {
                vao: vertexArrayObject,
                attributes: {
                    position: this.programInfo.attribLocations.vertexPosition != null ? this.initPositionAttribute(this.gl, this.programInfo, positions) : null
                },
                indicies: this.initIndexBuffer(this.gl, indices),
                numVertices: indices.length
            }
            resolve();
        })
    }

    reset() {
        return new Promise((resolve, reject) => {
            this.setup()
                .then(() => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }

    async setup(skyBox) {
        await this.getCubeMapTextures(skyBox);
        await this.loadCubeMap(this.model.skyBoxImages);
        await this.setShader();
        await this.initBuffers();
        this.loaded = true;
    }

    delete() {
        // Object.keys(this.buffers.attributes).forEach((key) => {
        //     this.gl.deleteBuffer(this.buffers.attributes[key]);
        // })
        // this.gl.deleteBuffer(this.buffers.indicies);
        console.log("Can't Delete Sky Box");
    }
}

export default SkyBox;
