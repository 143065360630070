import { useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/login'); // Move to Scene Selection
    };

    const handleLogoutClick = () => {
        localStorage.removeItem('jwt'); // Remove jwt
        navigate('/'); // Move to Scene Selection
    };

    return (
        <div className="ui raised segment" style={{ backgroundColor: '#00b5ad', position: 'relative' }}>
            <div className="ui center aligned container" style={{ textAlign: 'center' }}>
                <h2 className="ui inverted image header" style={{ backgroundColor: '#00b5ad', margin: 0 }}>
                    <img src="/craneIconWhite.png" alt="Construction Yard Logo" className="ui rounded image" />
                    <div className="content">
                        <h1 style={{ margin: 0 }}>
                            Construction Yard
                        </h1>
                    </div>
                </h2>
            </div>
            {localStorage.getItem('jwt') ? (
                <button className="ui inverted button" style={{ position: 'absolute', right: '3rem', top: '50%', transform: 'translateY(-50%)' }} onClick={() => handleLogoutClick()}>
                    Logout
                </button>
            ) : (
                <button className="ui inverted button" style={{ position: 'absolute', right: '10rem', top: '50%', transform: 'translateY(-50%)' }} onClick={() => handleLoginClick()}>
                    Login
                </button>
            )}
        </div>
    );
};

export default Header;